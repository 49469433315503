* {
  margin: 0;
}

form {
  margin-left: 20%;
  margin-top: 10%;
  position: relative;
}

html,
body {
  height: 100%;
  font-family: "Special Elite", cursive;
  justify-items: center;
  background-color: rgb(208, 208, 208);
}

h1 {
  top: 0;
  z-index: 5;
  position: fixed;
  width: 100%;
  text-align: center;
  color: black;
  margin: 2%;
  font-weight: 100;
  height: 5%;
}

h2 {
  text-align: center;
  margin: 5%;
}

ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

li {
  height: 40vh;
  flex-grow: 1;
}

li:last-child {
  flex-grow: 10;
}

img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
  box-shadow: 2px 5px 20px 7px black, -2px -7px 20px 17px rgb(223, 222, 222);
}

.calendly {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  box-shadow: 5px 5px 45px 45px;
  border: 5px solid red;
}

.contactForm {
  flex-direction: column;
}

.contactPage {
  margin-left: auto;
  margin-right: auto;
}

.container {
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 2%;
  position: relative;
  z-index: 4;
}

.gallery-image {
  margin: 1%;
}

.nav-list {
  display: inline;
  color: black;
  list-style-type: none;
}
.nav-list-item {
  margin: -10%;
  color: black;
  height: 10vh;
}

.schedule-btn {
  text-align: center;
}

.schdBtn {
  color: red;
}

.sidenav {
  color: black;
  height: 100%;
  width: 7%;
  position: fixed;
  z-index: 5;
  top: 10;
  left: 0;
  transition: 0.5s ease;
  padding-top: 1%;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 2%;
  padding-left: 2%;
  margin-top: 0.5%;
  list-style-type: none;
}

a:visited {
  color: black;
}
